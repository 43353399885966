import {Injectable} from '@angular/core';
import {
  Action,
  State,
  Selector,
  StateContext, createSelector,
} from '@ngxs/store';
import {patch} from '@ngxs/store/operators';
import {UserStateModel} from './user-state.models';
import {UserStateActions} from "./user-state.actions";

const STATE_NAME = 'UserState';

@State<UserStateModel>({
  name: STATE_NAME,
  defaults: {
    email: '',
    isLoggedIn: false,
    profile: null
  },
})
@Injectable()
export class UserState {
  @Selector()
  static user(state: UserStateModel): UserStateModel | null {
    return state;
  }

  @Selector()
  static isLoggedIn(state: UserStateModel): boolean {
    return state.isLoggedIn;
  }

  @Action(UserStateActions.SetEmail)
  setEmail(ctx: StateContext<UserStateModel>, action: UserStateActions.SetEmail) {
    ctx.setState(patch<UserStateModel>({
      email: action.email,
    }));
  }

  @Action(UserStateActions.SetIsLoggedIn)
  setIsLoggedIn(ctx: StateContext<UserStateModel>, action: UserStateActions.SetIsLoggedIn) {
    ctx.setState(patch<UserStateModel>({
      isLoggedIn: action.isLoggedIn
    }));
  }

  @Action(UserStateActions.SetProfile)
  setProfile(ctx: StateContext<UserStateModel>, action: UserStateActions.SetProfile) {
    ctx.setState(patch<UserStateModel>({
      profile: action.profile
    }));
  }


  /*  static eventById(id: number) {
    return createSelector([EventsState], (state: EventsStateModel): DbrEventEntity | undefined => {
      if (state.events && state.events.length) {
        return state.events.find((item)=> item.id === id);
      } else {
        return undefined;
      }
    });
  }*/

  // @Action(EventsStateActions.SetEvent)
  // setEvent(ctx: StateContext<EventsStateModel>, action: EventsStateActions.SetEvent) {
  //   const state = ctx.getState();
  //   const newListEvents = JSON.parse((JSON.stringify(state.events)));
  //   const index = state.events.findIndex((item)=> item.id === action.event.id);
  //   if (index > -1) {
  //     newListEvents[index] = action.event;
  //   } else {
  //     newListEvents.push(action.event);
  //   }
  //
  //   ctx.setState(patch<EventsStateModel>({
  //     events: newListEvents,
  //   }));
  // }
  //
  //
}

